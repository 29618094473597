import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Home from './HomeComponent';
import GetInvolved from './GetInvolvedComponent';
import About from './AboutComponent';
import Blog from './BlogComponent';
import Glossary from './GlossaryComponent';
import HowItWorks from './HowItWorksComponent';
import Dangers from './DangersComponent';
import Disparities from './DisparitiesComponent';
import Solutions from './SolutionsComponent';
import Article from './ArticleComponent';

class Main extends Component {

  render() {

    const ArticleWithId = ({match}) => {
      return(
        <Article link={match.params.article} location="/blog" />
      );
    };

    return (
      <div>
          <Switch location={this.props.location}>
              <Route path='/home' component={() => <Home location='/home' />} />
              <Route exact path='/get-involved' component={() => <GetInvolved location='/get-involved' />} />
              <Route exact path='/about' component={() => <About location='/about' />} />
              <Route exact path='/blog' component={() => <Blog location='/blog' />} />
              <Route path='/blog/:article' component={ArticleWithId} />
              <Route exact path='/glossary' component={() => <Glossary location='/glossary' />} />
              <Route exact path='/how-it-works' component={() => <HowItWorks location='/how-it-works' />} />
              <Route exact path='/dangers-of-detention' component={() => <Dangers location='/dangers-of-detention' />} />
              <Route exact path='/disparities-in-detention' component={() => <Disparities location='/disparities-in-detention' />} />
              <Route exact path='/solutions-and-steps' component={() => <Solutions location='/solutions-and-steps' />} />
              <Redirect to="/home" />
          </Switch>
      </div>
    );

  }
}
      
export default withRouter(Main);