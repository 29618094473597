import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import $ from 'jquery';
import Header from './HeaderComponent';
import { Waypoint } from 'react-waypoint';
import Footer from './FooterComponent';
import Headroom from 'react-headroom';

class HowItWorks extends Component {

    render() {

        return(
            <div>
                <Headroom>
                    <Header location={this.props.location} />
                </Headroom>  
                <div className="blue-theme">
                    <div className="container">
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-6 col-4">
                                                    <img src="/assets/images/process-1.png" className="progress-dots img-fluid" alt="1 of 4"></img>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="section-title">How it Works</h1>
                                                </div>
                                            </div>                                    
                                        </div>
                                        <div className="col-12">
                                            <p className="main-text">Most people would agree that adults and children have very different needs and abilities. Countless <span className="subarticle">research studies</span> have come to the same conclusion.</p>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <p className="supplemental-text">Children are still developing, so they are less blameworthy and have greater potential for rehabilitation. <span id="r1" className="ref-number">[1]</span></p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row justify-content-center">
                                                <div className="col-md-8 col-6 img-overlay">
                                                    <img src="/assets/images/head.png" className="img-fluid" alt="Head"></img>
                                                    <img id="spiral" src="/assets/images/spiral.png" className="img-fluid" alt="Spiral"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">Concerned about the treatment of juveniles, the Progressive Era took this idea and ran with it. <span className="active-sub subarticle"><a href="/blog/:juvenile-justice-timeline">By 1925,</a></span> most states had adopted separate legal proceedings for children who were accused of breaking the law.</p>
                                                </div>
                                            </div>
                                            <Waypoint onEnter={() => {$('#chain-2').addClass('break'); $('#chain-3').addClass('show-break');}}>
                                                <div className="row">
                                                    <div className="col-6 img-overlay">
                                                        <img id="chain-1" src="/assets/images/chain-1.png" className="img-fluid" alt="Broken chain"></img>
                                                        <img id="chain-2" src="/assets/images/chain-2.png" className="img-fluid" alt="Broken chain"></img>
                                                        <img id="chain-3" src="/assets/images/chain-3.png" className="img-fluid" alt="Broken chain"></img>
                                                    </div>
                                                </div>
                                            </Waypoint>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="bubble-text">And so</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">the juvenile justice system was born.</p>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">Retribution would be <span className="subarticle">saved for the adults</span>, while the juvenile justice system focused on <span className="em-text">prevention</span> and <span className="em-text">rehabilitation</span>. <span id="r2" className="ref-number">[2]</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="section-title">How it's Broken</h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">Since its inception, the juvenile justice system has had an enormous and multifaceted impact on America’s youth.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">On any given day, around 2,000 youths in the US are arrested. <span id="r3" className="ref-number">[3]</span></p>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col">
                                                    <img src="/assets/images/2000.png" className="img-fluid" alt="2000"></img>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">Those that do not have their delinquency referrals dismissed or informally handled, like through diversion programs, are formally processed in court.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <p className="main-text">Of the young people then adjudicated delinquent (essentially found guilty in court),</p>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-6">
                                                    <div className="row justify-content-center">
                                                        <div className="col col-md-6">
                                                            <p className="main-text"><strong>1 in 4 won’t return home.</strong></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row justify-content-md-start justify-content-center">
                                                        <div className="col-6">
                                                            <img src="/assets/images/1in4.png" className="img-fluid" alt="1 in 4"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Instead, they will be removed from their communities and placed into juvenile residential facilities. <span id="r4" className="ref-number">[4]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blue-theme-transition">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="row inner-section">
                                    <div className="col-2 blue-theme"></div>
                                    <div className="offset-3 col-2 blue-theme"></div>
                                    <div className="offset-3 col-2 blue-theme"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="section-title">Kids Behind Bars</h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">Several features distinguish the juvenile justice system from its adult counterpart, but one troubling similarity stands out: an overreliance on confinement. While youth incarceration has halved in the past 50 years, the US still incarcerates more young people than any other country in the world. <span id="r5" className="ref-number">[5]</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row inner-section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">On any given day, over 43,000 youths in the US are confined in juvenile facilities. <span id="r6" className="ref-number">[6]</span> Nearly 1 in 3 of them are under 16 years old. <span id="r7" className="ref-number">[7]</span></p>
                                                </div>
                                            </div>
                                            <br></br>
                                            <Waypoint onEnter={() => {$('#lock-top').addClass('lock');}}>
                                                <div className="row justify-content-center">
                                                    <div className="col-10 img-overlay">
                                                        <img id="locks" src="/assets/images/locks.png" className="img-fluid" alt="1 in 3"></img>
                                                        <img id="lock-top" src="/assets/images/lock-top.png" className="img-fluid" alt="1 in 3"></img>
                                                    </div>
                                                </div>                                                
                                            </Waypoint>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">These children are away from home and missing out on opportunities that are vital for their life chances. At this early, crucial stage in their academic and personal development, some of society’s most vulnerable children are being locked up and left behind.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <a className="btn main-article-button" href="/dangers-of-detention" role="button">Continue reading to learn about the <br></br>Dangers of Detention</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer location="/how-it-works"/>
                <div id="how-it-works-references">
                    <div className="references blue-theme">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h1>References</h1>
                                </div>
                                <ol>
                                    <li>Walsh, N., &#38; Weber, J. (2014). Measuring and Using Juvenile Recidivism Data to Inform Policy, Practice, and Resource Allocation. Council of State Governments Justice Center. <a href="https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf">https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf</a></li>
                                    <li>American Civil Liberties Union. (n.d.). ACLU Fact Sheet on the Juvenile Justice System. Retrieved June 11, 2021, from <a href="https://www.aclu.org/other/aclu-fact-sheet-juvenile-justice-system">https://www.aclu.org/other/aclu-fact-sheet-juvenile-justice-system</a></li>
                                    <li>Children’s Defense Fund. (2020). Youth Justice.<a href="https://www.childrensdefense.org/policy/policy-priorities/youth-justice/">https://www.childrensdefense.org/policy/policy-priorities/youth-justice/</a></li>
                                    <li>Sickmund, M., Sladky, A., &#38; Kang, W. (2020). Easy access to juvenile court statistics: 1997–2019 (database). Retrieved from <a href="www.ojjdp.gov/ojstatbb/ezajcs/">www.ojjdp.gov/ojstatbb/ezajcs/</a></li>
                                    <li>American Civil Liberties Union. (n.d.-b). Youth Incarceration. Retrieved June 4, 2021, from <a href="https://www.aclu.org/issues/juvenile-justice/youth-incarceration">https://www.aclu.org/issues/juvenile-justice/youth-incarceration</a></li>
                                    <li>Children’s Defense Fund. (2020). Youth Justice. <a href="https://www.childrensdefense.org/policy/policy-priorities/youth-justice/">https://www.childrensdefense.org/policy/policy-priorities/youth-justice/</a></li>
                                    <li>Sawyer, W. (2019). Youth Confinement: The Whole Pie 2019. Prison Policy Initiative. <a href="https://www.prisonpolicy.org/reports/youth2019.html">https://www.prisonpolicy.org/reports/youth2019.html</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <UncontrolledTooltip placement="top" autohide={false} target="r1">
                Walsh, N., &#38; Weber, J. (2014). Measuring and Using Juvenile Recidivism Data to Inform Policy, Practice, and Resource Allocation. Council of State Governments Justice Center. <a href="https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf">https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r2">
                    American Civil Liberties Union. (n.d.). ACLU Fact Sheet on the Juvenile Justice System. Retrieved June 11, 2021, from <a href="https://www.aclu.org/other/aclu-fact-sheet-juvenile-justice-system">https://www.aclu.org/other/aclu-fact-sheet-juvenile-justice-system</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r3">
                    Children’s Defense Fund. (2020). Youth Justice. <a href="https://www.childrensdefense.org/policy/policy-priorities/youth-justice/">https://www.childrensdefense.org/policy/policy-priorities/youth-justice/</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r4">
                    Sickmund, M., Sladky, A., &#38; Kang, W. (2020). Easy access to juvenile court statistics: 1997–2019 (database). Retrieved from <a href="www.ojjdp.gov/ojstatbb/ezajcs/">www.ojjdp.gov/ojstatbb/ezajcs/</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r5">
                    American Civil Liberties Union. (n.d.-b). Youth Incarceration. Retrieved June 4, 2021, from <a href="https://www.aclu.org/issues/juvenile-justice/youth-incarceration">https://www.aclu.org/issues/juvenile-justice/youth-incarceration</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r6">
                    Children’s Defense Fund. (2020). Youth Justice. <a href="https://www.childrensdefense.org/policy/policy-priorities/youth-justice/">https://www.childrensdefense.org/policy/policy-priorities/youth-justice/</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r7">
                    Sawyer, W. (2019). Youth Confinement: The Whole Pie 2019. Prison Policy Initiative. <a href="https://www.prisonpolicy.org/reports/youth2019.html">https://www.prisonpolicy.org/reports/youth2019.html</a>
                </UncontrolledTooltip>
            </div>



        );
    }
}

export default HowItWorks;