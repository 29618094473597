import React, { Component } from 'react';
import $ from 'jquery';
import { UncontrolledTooltip } from 'reactstrap';
import { Waypoint } from 'react-waypoint';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Headroom from 'react-headroom';

class Dangers extends Component {

    render() {
        return(
            <div>
                <Headroom>
                    <Header location={this.props.location} />
                </Headroom>
                <div className="dark-blue-theme">
                    <div className="container">
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-6 col-4">
                                                    <img src="/assets/images/process-2.png" className="progress-dots img-fluid" alt="2 of 4"></img>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="special-section-title">
                                                        <h1 className="section-title">The DANGERS</h1>
                                                        <p>of DETENTION</p>
                                                    </div>
                                                </div>
                                            </div>                                    
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-7 col-md-5 col-lg-4">
                                            <img src="/assets/images/MENTAL-HEALTH.png" alt="Mental Health" className="img-fluid"></img>
                                        </div>
                                    </div>
                                    <br></br><br></br>
                                    <div className="row">
                                        <div className="col">
                                            <p className="main-text">Youths in the juvenile justice system have <span className="subarticle">high rates</span> of mental health disorder, trauma, and suicidality rates compared to their peers. <span id="r8" className="ref-number">[1]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col-md-6">
                                            <p className="main-text">2 in 3 males and 3 in 4 females have been found to meet the criteria for at least one mental health disorder. <span id="r9" className="ref-number">[2]</span></p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-8">
                                                    <img src="/assets/images/mental-health-statistics.png" alt="" className="img-fluid"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col-md-6">
                                            <p className="main-text">1 in 10 meet criteria for a substance use disorder. <span id="r10" className="ref-number">[3]</span></p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-8">
                                                    <img src="/assets/images/substance-use-statistic.png" alt="" className="img-fluid"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Being forcibly separated from support systems, such as family and friends, can be deeply traumatic. <span id="r11" className="ref-number">[4]</span> Overcrowding and the use of solitary confinement or restrictive housing can also worsen pre-existing mental health issues. <span id="r12" className="ref-number">[5]</span></p>
                                            <br></br>
                                            <p className="main-text d-none d-md-block">Detained youth have also reported being unsure about whom to contact or where to go for help, and others believed it was too difficult to obtain help within juvenile justice facilities. <span id="r13" className="ref-number">[6]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center d-md-none">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Detained youth have also reported being unsure about whom to contact or where to go for help, and others believed it was too difficult to obtain help within juvenile justice facilities. <span id="r13" className="ref-number">[6]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dark-blue-transition-theme">
                    <div className="container">
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-7 col-md-5 col-lg-4">
                                                    <img src="/assets/images/EDUCATION.png" alt="Education" className="img-fluid"></img>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <br></br><br></br>
                                                    <p className="main-text">The way that education is delivered to children involved in the juvenile justice system varies from state to state.</p>
                                                    <br></br>
                                                    <p className="main-text">The patchwork of policies and responsibilities means that many children slip through the cracks without the quality education that they deserve.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col">
                                                    <p className="main-text">A <span className="subarticle">profound lack</span> of support services and unaddressed learning, psychological, and health problems further compound structural obstacles to learning. <span className="subarticle">2 in 3</span> youths who leave the juvenile justice system drop out of school. <span id="r14" className="ref-number">[7]</span></p>
                                                </div>
                                            </div>
                                            <Waypoint onEnter={() => {$('#ball-2').addClass('drop-1'); $('#ball-3').addClass('drop-2');}}>
                                                <div className="row justify-content-center">
                                                    <div className="col col-md-3 img-overlay">
                                                        <img id="ball-1" src="/assets/images/ball.png" alt="" className="img-fluid ball"></img>
                                                        <img src="/assets/images/ball-shadow.png" alt="" className="img-fluid ball-shadow"></img>
                                                    </div>
                                                    <div className="col col-md-3 img-overlay">
                                                        <img id="ball-2" src="/assets/images/ball.png" alt="" className="img-fluid ball"></img>
                                                        <img src="/assets/images/ball-shadow.png" alt="" className="img-fluid ball-shadow"></img>
                                                    </div>
                                                    <div className="col col-md-3 img-overlay">
                                                        <img id="ball-3" src="/assets/images/ball.png" alt="" className="img-fluid ball"></img>
                                                        <img src="/assets/images/ball-shadow.png" alt="" className="img-fluid ball-shadow"></img>
                                                    </div>
                                                </div>
                                            </Waypoint>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Incarcerated students often lag behind in pass rates and have access to fewer higher-level courses. Being denied a quality education denies justice-involved youths the skills needed to become independent, productive adult members of their communities. <span id="r15" className="ref-number">[8]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Instead of being a rehabilitative turning point, youth incarceration can shackle children with poor health, education, and employment outcomes for the rest of their lives.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dark-blue-theme">
                    <div className="container">
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-7 col-md-5 col-lg-4">
                                                    <img src="/assets/images/PRIVACY.png" alt="Privacy" className="img-fluid"></img>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <br></br><br></br>
                                                    <p className="main-text">Gone are the days where “detention” referred solely to being confined to a cell. From ankle monitors that double as listening devices <span id="r16" className="ref-number">[9]</span> to databases that try to calculate a child’s likelihood to commit crime, <span id="r17" className="ref-number">[10]</span> technology can turn personal communities into prisons.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">The increasing use of technology to deprive people of their liberty is known as <span className="subarticle">e-carceration.</span> <span id="r18" className="ref-number">[11]</span></p>
                                                </div>
                                            </div>
                                            <Waypoint onEnter={() => {$('#foot-1').addClass('tilt-right'); $('#foot-3').addClass('tilt-right'); $('#foot-2').addClass('tilt-left'); $('#foot-4').addClass('tilt-left');} }>
                                                <div className="row justify-content-center">
                                                    <div className="col-11 col-md-8">
                                                        <img id="foot-1" src="/assets/images/foot.png" alt="Ankle monitors" className="foot"></img>
                                                        <img id="foot-2" src="/assets/images/foot.png" alt="Ankle monitors" className="foot"></img>
                                                        <img id="foot-3" src="/assets/images/foot.png" alt="Ankle monitors" className="foot"></img>
                                                        <img id="foot-4" src="/assets/images/foot.png" alt="Ankle monitors" className="foot"></img>
                                                    </div>
                                                </div>
                                            </Waypoint>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Shackled with electronic GPS devices and stringent mobility restrictions, youth on probation escape one form of incarceration only to end up with another. With numerous strict rules dictating who they can see, where they can go, and when, youth can end up missing out on extracurricular opportunities, jobs, and prosocial interactions with friends. <span id="r19" className="ref-number">[12]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">E-carceration is eroding the <span className="subarticle">community-based rehabilitation</span> that traditionally separated probation from facility detention.</p>
                                                    <br></br>
                                                    <p className="main-text">As new forms of surveillance creep into the juvenile justice system, dignified reintegration and privacy rights have taken a backseat to new, unusual methods of punishment.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <a className="btn main-article-button" href="/disparities-in-detention" role="button">Continue reading to learn about the <br></br>Disparities in Detention</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer location="/dangers-of-detention"/>
                    <div id="dangers-of-detention-references">
                        <div className="references dark-blue-transition-theme">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <h1>References</h1>
                                    </div>
                                    <ol>
                                        <li>Underwood, L. A., &#38; Washington, A. (2016). Mental Illness and Juvenile Offenders. International journal of environmental research and public health, 13(2), 228. <a href="https://doi.org/10.3390/ijerph13020228">https://doi.org/10.3390/ijerph13020228</a></li>
                                        <li>Underwood, L. A., &#38; Washington, A. (2016). Mental Illness and Juvenile Offenders. International journal of environmental research and public health, 13(2), 228. <a href="https://doi.org/10.3390/ijerph13020228">https://doi.org/10.3390/ijerph13020228</a></li>
                                        <li>Underwood, L. A., &#38; Washington, A. (2016). Mental Illness and Juvenile Offenders. International journal of environmental research and public health, 13(2), 228. <a href="https://doi.org/10.3390/ijerph13020228">https://doi.org/10.3390/ijerph13020228</a></li>
                                        <li>Development Services Group, Inc. (2017). Intersection Between Mental Health and the Juvenile Justice System. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/media/document/intersection-mental-health-juvenile-justice.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/media/document/intersection-mental-health-juvenile-justice.pdf</a></li>
                                        <li>Lynch, L.E., &#38; Mears, D. (2016). Restrictive Housing in the U.S.: Issues, Challenges, and Future Directions.</li>
                                        <li>Abram, K. M., Paskar, L. D., Washburn, J. J., Zwecker, N. A., Azores-Gococo, N. M., &#38; Teplin, L. A. (2015). Perceived Barriers to Mental Health Services Among Detained Youth. Juvenile Justice Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://files.eric.ed.gov/fulltext/ED590912.pdf">https://files.eric.ed.gov/fulltext/ED590912.pdf</a></li>
                                        <li>Suitts, S., Dunn, K., &#38; Sabree, N. (2014). Just Learning: The Imperative to Transform Juvenile Justice Systems into Effective Educational Systems. A Study of Juvenile Justice Schools in the South and the Nation.</li>
                                        <li>Suitts, S., Dunn, K., &#38; Sabree, N. (2014). Just Learning: The Imperative to Transform Juvenile Justice Systems into Effective Educational Systems. A Study of Juvenile Justice Schools in the South and the Nation.</li>
                                        <li>Lerner, K. (2018, April 8). Chicago’s Ankle Monitors Can Call and Record Kids Without Their Consent. Bloomberg. <a href="https://www.bloomberg.com/news/articles/2019-04-08/ankle-monitors-introduce-a-new-form-of-surveillance">https://www.bloomberg.com/news/articles/2019-04-08/ankle-monitors-introduce-a-new-form-of-surveillance</a></li>
                                        <li>Kwet, M. (2020, December 21). Microsoft’s iron cage: Prison surveillance and e-carceration. Al Jazeera. <a href="https://www.aljazeera.com/features/2020/12/21/microsofts-iron-cage-prison-surveillance-and-e-carceral-state">https://www.aljazeera.com/features/2020/12/21/microsofts-iron-cage-prison-surveillance-and-e-carceral-state</a></li>
                                        <li>Taylor, V. (2021, March 30). What is e-carceration? Understanding the U.S. justice system’s dirty little secret. Mic. <a href="https://www.mic.com/p/what-is-e-carceration-understanding-the-us-justice-systems-dirty-little-secret-66261004">https://www.mic.com/p/what-is-e-carceration-understanding-the-us-justice-systems-dirty-little-secret-66261004</a></li>
                                        <li>Loudenback, J. (2017). Report Raises Alarm About GPS Monitors for Probation Youth. The Imprint. <a href="https://imprintnews.org/news-2/report-raises-alarm-gps-monitors-probation-youth/27548">https://imprintnews.org/news-2/report-raises-alarm-gps-monitors-probation-youth/27548</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UncontrolledTooltip placement="top" autohide={false} target="r8">
                    Underwood, L. A., &#38; Washington, A. (2016). Mental Illness and Juvenile Offenders. International journal of environmental research and public health, 13(2), 228. <a href="https://doi.org/10.3390/ijerph13020228">https://doi.org/10.3390/ijerph13020228</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r9">
                    Underwood, L. A., &#38; Washington, A. (2016). Mental Illness and Juvenile Offenders. International journal of environmental research and public health, 13(2), 228. <a href="https://doi.org/10.3390/ijerph13020228">https://doi.org/10.3390/ijerph13020228</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r10">
                    Underwood, L. A., &#38; Washington, A. (2016). Mental Illness and Juvenile Offenders. International journal of environmental research and public health, 13(2), 228. <a href="https://doi.org/10.3390/ijerph13020228">https://doi.org/10.3390/ijerph13020228</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r11">
                    Development Services Group, Inc. (2017). Intersection Between Mental Health and the Juvenile Justice System. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/media/document/intersection-mental-health-juvenile-justice.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/media/document/intersection-mental-health-juvenile-justice.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r12">
                    Lynch, L.E., &#38; Mears, D. (2016). Restrictive Housing in the U.S.: Issues, Challenges, and Future Directions.
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r13">
                    Abram, K. M., Paskar, L. D., Washburn, J. J., Zwecker, N. A., Azores-Gococo, N. M., &#38; Teplin, L. A. (2015). Perceived Barriers to Mental Health Services Among Detained Youth. Juvenile Justice Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://files.eric.ed.gov/fulltext/ED590912.pdf">https://files.eric.ed.gov/fulltext/ED590912.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r14">
                    Suitts, S., Dunn, K., &#38; Sabree, N. (2014). Just Learning: The Imperative to Transform Juvenile Justice Systems into Effective Educational Systems. A Study of Juvenile Justice Schools in the South and the Nation.
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r15">
                    Suitts, S., Dunn, K., &#38; Sabree, N. (2014). Just Learning: The Imperative to Transform Juvenile Justice Systems into Effective Educational Systems. A Study of Juvenile Justice Schools in the South and the Nation.
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r16">
                    Lerner, K. (2018, April 8). Chicago’s Ankle Monitors Can Call and Record Kids Without Their Consent. Bloomberg. <a href="https://www.bloomberg.com/news/articles/2019-04-08/ankle-monitors-introduce-a-new-form-of-surveillance">https://www.bloomberg.com/news/articles/2019-04-08/ankle-monitors-introduce-a-new-form-of-surveillance</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r17">
                    Kwet, M. (2020, December 21). Microsoft’s iron cage: Prison surveillance and e-carceration. Al Jazeera. <a href="https://www.aljazeera.com/features/2020/12/21/microsofts-iron-cage-prison-surveillance-and-e-carceral-state">https://www.aljazeera.com/features/2020/12/21/microsofts-iron-cage-prison-surveillance-and-e-carceral-state</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r18">
                    Taylor, V. (2021, March 30). What is e-carceration? Understanding the U.S. justice system’s dirty little secret. Mic. <a href="https://www.mic.com/p/what-is-e-carceration-understanding-the-us-justice-systems-dirty-little-secret-66261004">https://www.mic.com/p/what-is-e-carceration-understanding-the-us-justice-systems-dirty-little-secret-66261004</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r19">
                    Loudenback, J. (2017). Report Raises Alarm About GPS Monitors for Probation Youth. The Imprint. <a href="https://imprintnews.org/news-2/report-raises-alarm-gps-monitors-probation-youth/27548">https://imprintnews.org/news-2/report-raises-alarm-gps-monitors-probation-youth/27548</a>
                </UncontrolledTooltip>
            </div>

        );
    }
}

export default Dangers;