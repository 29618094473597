import React, { Component } from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

class Header extends Component {
    constructor(props) {
        super(props);
    
        this.toggleNav = this.toggleNav.bind(this);
        this.state = {
            isNavOpen: false
        };
    }

    getTheme(path){
        let brandColor = "";
        switch(path){
            default:
                brandColor = "black";
                break;
            case '/home':
                brandColor = "black";
                break;
            case '/get-involved':
                brandColor = "dark-blue";
                break;
            case '/about':
                brandColor = "black";
                break;
            case '/blog':
                brandColor = "black";
                break;
            case '/glossary':
                brandColor = "black";
                break;
            case '/how-it-works':
                brandColor = "blue";
                break;
            case '/disparities-in-detention':
                brandColor = "red";
                break;
            case '/dangers-of-detention':
                brandColor = "dark-blue";
                break;
            case '/solutions-and-steps':
                brandColor = "purple";
                break;
        }
        return brandColor;
    }

    componentDidMount(){
        let brandColor = this.getTheme(this.props.location);
        let fontColor;
        switch(brandColor){
            default:
                fontColor = "black";
                break;
            case "black":
                fontColor = "black";
                break;
            case "blue":
                fontColor = "#0D243F";
                break;
            case "dark-blue":
                fontColor = "white";
                break;
            case "red":
                fontColor = "#D72344";
                break;
            case "purple":
                fontColor = "#380343";
                break;
        }
        $('#nav-div').attr("class", `${brandColor}-theme`);
        $('.nav-link').css("color", fontColor);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    render() {
        return(
            <div id="nav-div">
                <div className="container">
                    <div className="row">
                        <Navbar dark={this.getTheme(this.props.location)==="dark-blue"} light={this.getTheme(this.props.location)!=="dark-blue"} expand="sm justify-content-between" className="align-items-start">
                            <div id="brand-container" className="order-2 order-md-1 col-3 col-md-2">
                                <NavbarBrand href="/home"><img src={`/assets/images/logo-${this.getTheme(this.props.location)}.png`} id="brand-img" className="img-fluid" alt='Legal Lark' /></NavbarBrand>
                            </div>
                            <div className="order-1 order-md-2 col-auto">
                                <NavbarToggler onClick={this.toggleNav} />
                                <Collapse isOpen={this.state.isNavOpen} navbar >
                                    <Nav navbar>
                                        <NavItem id="main-article-nav">
                                            <NavLink className="nav-link" to='/how-it-works'>Main Article</NavLink>
                                        </NavItem>
                                        <NavItem id="get-involved-nav">
                                            <NavLink className="nav-link" to='/get-involved'>Get Involved</NavLink>
                                        </NavItem>
                                        <NavItem id="about-nav">
                                            <NavLink className="nav-link" to='/about'>About</NavLink>
                                        </NavItem>
                                        <NavItem id="blog-nav">
                                            <NavLink className="nav-link" to='/blog'>Blog</NavLink>
                                        </NavItem>
                                        <NavItem id="glossary-nav">
                                            <NavLink className="nav-link"  to='/glossary'>Glossary</NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </div>
                        </Navbar>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;