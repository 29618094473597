import React, { Component } from 'react';

import { Modal, ModalHeader, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from "react-simple-maps";

import allStates from "../data/states.json";
import { ORGANIZATIONS } from "../data/organizations";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
  HI: [0, 0],
  FL: [0, 0]
};

function RenderLinks({stateId}) {
    let links = ORGANIZATIONS.filter((source) => source.state_id === stateId );
    const resources = links.map((link) => {
        return (
            <p key={link.id}>
                <a href={link.link} rel="noreferrer" target="_blank">{link.name}</a>
            </p>
        );
    });
    return resources;
}

class Support extends Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleState = this.toggleState.bind(this);
        this.getColor = this.getColor.bind(this);
        this.linkIfDC = this.linkIfDC.bind(this);

        this.state = {
            isModalOpen: false,
            isDropdownOpen: false,
            activeState: '',
            activeStateId: ''
        };
    }

    renderStates() {
        const states = allStates.map((state) => {
            return (
                <DropdownItem key={state.val} onClick={(e) => this.toggleState(state.id, state.name)}>
                    {state.id}
                </DropdownItem>
            );
        });
        return states;
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    toggleDropdown() {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    }

    toggleState(id, state) {
        this.setState({
            activeStateId: id,
            activeState: state
        });
    }

    getColor(id) { 
        if(id==="FL") {
            return "white";
        }
        else {
            return "#261004";
        }
    }
    
    linkIfDC(id) {
        if(id==="DC") {
            return (
                <text onClick={() => {this.toggleModal(); this.toggleState("DC", "Washington D.C.")}} fontWeight="bold" fill="#FC6E21" textDecoration="underline" cursor="pointer" x={4} fontSize={14} alignmentBaseline="middle">
                {id}
                </text>
            );
        }
        else {
            return (
                <text fontWeight="bold" fill={this.getColor(id)} x={4} fontSize={14} alignmentBaseline="middle">
                {id}
                </text>
            );
        }
    }

    

    render() {
        return(
            <div>
                <div className="d-none d-lg-block">
                    <h1 className="get-involved-header">Explore Local Organizations</h1>
                    <ComposableMap projection="geoAlbersUsa">
                        <Geographies geography={geoUrl}>
                            {({ geographies }) => (
                            <>
                                {geographies.map(geo => (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    onClick={() => {this.toggleModal(); this.toggleState(allStates.find(s => s.val === geo.id).id, geo.properties.name);}}
                                    style={{
                                        default: {
                                            fill: "#FC6E21",
                                            stroke: "white",
                                            strokeWidth: 2
                                        },
                                        hover: {
                                            fill: "#246394",
                                            stroke: "white",
                                            strokeWidth: 2,
                                            cursor: "pointer"
                                        },
                                        pressed: {
                                            fill: "#246394",
                                            stroke: "white",
                                            strokeWidth: 2,
                                            cursor: "pointer"
                                        }
                                    }}
                                />
                                ))}
                                {geographies.map(geo => {
                                const centroid = geoCentroid(geo);
                                const cur = allStates.find(s => s.val === geo.id);
                                return (
                                    <g key={geo.rsmKey + "-name"}>
                                    {cur &&
                                        centroid[0] > -160 &&
                                        centroid[0] < -67 &&
                                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                        <Marker coordinates={centroid}>
                                            <text fontWeight="bold" fill="white" y="2" fontSize={14} textAnchor="middle">
                                            {cur.id}
                                            </text>
                                        </Marker>
                                        ) : (
                                        <Annotation
                                            subject={centroid}
                                            dx={offsets[cur.id][0]}
                                            dy={offsets[cur.id][1]}
                                        >
                                            {this.linkIfDC(cur.id)}
                                        </Annotation>
                                        ))}
                                    </g>
                                );
                                })}
                            </>
                            )}
                        </Geographies>
                    </ComposableMap>
                    <Modal size="lg" id="support-modal" isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>{this.state.activeState}</ModalHeader>
                        <ModalBody>
                            <RenderLinks stateId={this.state.activeStateId} />
                        </ModalBody>
                    </Modal>
                </div>

                <div id="support-mobile" className="container d-lg-none">
                    <div className="row justify-content-center justify-content-sm-start">
                        <div className="col-auto col-md-6">
                             <br></br>
                             <Dropdown isOpen={this.state.isDropdownOpen} toggle={this.toggleDropdown}>
                                <DropdownToggle caret>
                                    Find support in your state
                                </DropdownToggle>
                                <DropdownMenu modifiers={{
                                    setMaxHeight: {
                                        enabled: true,
                                        order: 890,
                                        fn: (data) => {
                                        return {
                                            ...data,
                                            styles: {
                                            ...data.styles,
                                            overflow: 'auto',
                                            maxHeight: '150px',
                                            },
                                        };
                                        },
                                    },
                                }}>
                                    {this.renderStates()}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <br></br><br></br>
                    <div className="row">
                        <div className="col">
                            <h2>{this.state.activeState}</h2>
                            <RenderLinks stateId={this.state.activeStateId} />                              
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Support;