import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Headroom from 'react-headroom';

class Disparities extends Component {

    render() {
        return(
            <div>
                <Headroom>
                    <Header location={this.props.location} />
                </Headroom>
                <div className="red-theme">
                    <div className="container">
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-6 col-4">
                                                    <img src="/assets/images/process-3.png" className="progress-dots img-fluid" alt="3 of 4"></img>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="special-section-title">
                                                        <h1 className="section-title">The Disparities</h1>
                                                        <p>in DETENTION</p>
                                                    </div>
                                                </div>
                                            </div>                                    
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-md-4 col-lg-3">
                                                <img src="/assets/images/RACE.png" alt="Race" className="img-fluid"></img>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <br></br><br></br>
                                                <p className="main-text">Youth of color are disproportionately impacted by the juvenile justice system. Data on differences in offending only tells part of the story.</p>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Black youths face multiple layers of <span className="subarticle">disadvantage</span> that ultimately make it more likely for these youths to be subjected to policing, punishment, and arrest compared to white youths. <span id="r20" className="ref-number">[1]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Even after accounting for demographic, family, and neighborhood condition predictors, there are still substantial residual arrest differences between youths of different racial and ethnic groups. <span id="r21" className="ref-number">[2]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">Research <span id="r22" className="ref-number">[3]</span> by The Sentencing Project found that compared to <em>white youth</em>:</p>
                                                    <br></br>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col col-md-10">
                                                    <img src="/assets/images/sentencing-project-stat.png" alt="Black youth are 5x more likely to be incarcerated, American Indian youth 3x more likely, and Latinx youth 42% more likely." className="img-fluid"></img>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-6 col-md-4 col-lg-3">
                                                    <img src="/assets/images/GENDER.png" alt="Gender" className="img-fluid"></img>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <br></br><br></br>
                                                    <p className="main-text">Discussions and investments into juvenile justice reform have historically focused on boys. <span id="r23" className="ref-number">[4]</span> Despite only representing 28% of the delinquency cases handled by juvenile courts, double standards and a lack of gender-responsive services create unique challenges for girls within the juvenile justice system. <span id="r24" className="ref-number">[5]</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">In 2015, the <span className="subarticle">majority of females</span> confined in residential placement were being held for nonviolent offenses. <span id="r25" className="ref-number">[6]</span></p>
                                                </div>
                                            </div>
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 col-md-5">
                                                    <p className="supplemental-text">1 in 3 of these placements were due to technical violations and status offenses, like skipping school and running away. <span id="r26" className="ref-number">[7]</span></p>
                                                </div>
                                                <div className="col-8 col-md-5">
                                                    <img src="/assets/images/technical-violations.png" alt="" className="img-fluid"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col-12 col-md">
                                            <p className="supplemental-text">These are children who pose little to no threat to public safety, but are still exposed to the harmful, often re-traumatizing experience of confinement. <span id="r27" className="ref-number">[8]</span></p>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="row justify-content-center">
                                                <div className="col-8">
                                                    <img src="/assets/images/confinement.png" alt="" className="img-fluid"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col-12 col-md">
                                            <p className="supplemental-text">Girls who identify as LGBTQ+ also experience disparities in treatment.</p>
                                            <p className="supplemental-text">Non-heterosexual girls are twice as likely to be arrested and convicted as other girls who had engaged in similar behavior. <span id="r28" className="ref-number">[9]</span></p>
                                        </div>
                                        <div className="col-12 col-md">
                                            <img src="/assets/images/lgbtq-disparities.png" alt="" className="img-fluid"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Overlooked and continuously failed, <span className="subarticle">girls and gender expansive youth</span> are falling through gaps in the child-welfare <span className="subarticle">and juvenile justice systems.</span> <span id="r29" className="ref-number">[10]</span> The juvenile justice system has often, and wrongly, been depended on as an alternative to sustainably safeguarding children with unaddressed trauma and/or unmet mental, physical, and socioeconomic needs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col col-md-7">
                                                    <img src="/assets/images/abuse-chart.png" alt="80% of girls in the juvenile justice system have been sexually or physically abused" className="img-fluid"></img>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <br></br>
                                                    <p className="supplemental-text"><em>According to a report by the Vera Institute <span id="r30" className="ref-number">[11]</span></em></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Rather than focusing on prevention and rehabilitation, society overrelies on incarceration. This criminalizes children for behaviors related to forms of trauma and violence that disproportionately harms girls and gender-expansive youth. <span id="r31" className="ref-number">[12]</span></p>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <a className="btn main-article-button" href="/solutions-and-steps" role="button">Continue reading to learn about <br></br>Solutions and Steps</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer location="/disparities-in-detention"/>
                    <div id="disparities-in-detention-references">
                        <div className="references red-transition-theme">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <h1>References</h1>
                                    </div>
                                    <ol>
                                        <li>Monk, E. P. (2018). The color of punishment: African Americans, skin tone, and the criminal justice system. Ethnic and Racial Studies, 42(10), 1593–1612. <a href="https://doi.org/10.1080/01419870.2018.1508736">https://doi.org/10.1080/01419870.2018.1508736</a></li>
                                        <li>Kirk, D. S. (2008). The neighborhood context of racial and ethnic disparities in arrest. Demography, 45(1), 55–77. <a href="https://doi.org/10.1353/dem.2008.0011">https://doi.org/10.1353/dem.2008.0011</a></li>
                                        <li>Rovner, J. (2021). Racial Disparities in Youth Incarceration Persists. The Sentencing Project. <a href="https://www.sentencingproject.org/publications/racial-disparities-in-youth-incarceration-persist/">https://www.sentencingproject.org/publications/racial-disparities-in-youth-incarceration-persist/</a></li>
                                        <li>The Vera Institute of Justice. (n.d.). Vera’s 10-Year Strategy to End Girls’ Incarceration. Vera Institute of Justice. Retrieved May 11, 2021, from <a href="https://www.vera.org/publications/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration">https://www.vera.org/publications/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration</a></li>
                                        <li>Ehrmann, S., Hyland, N., &#38; Puzzanchera, C. (2019). Girls in the Juvenile Justice System. National Report Series Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf</a></li>
                                        <li>Ehrmann, S., Hyland, N., &#38; Puzzanchera, C. (2019). Girls in the Juvenile Justice System. National Report Series Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf</a></li>
                                        <li>Ehrmann, S., Hyland, N., &#38; Puzzanchera, C. (2019). Girls in the Juvenile Justice System. National Report Series Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf</a></li>
                                        <li>Office of Juvenile Justice and Delinquency Prevention (2015). Girls and the Juvenile Justice System Policy Guidance. OJJDP Girls Study Group Series. <a href="https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf">https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf</a></li>
                                        <li>Himmelstein, K. E. W., &#38; Brückner, H. (2011). Criminal-justice and school sanctions against nonheterosexual youth: A national longitudinal study. Pediatrics, 127(1), 49-57. <a href="https://doi.org/10.1542/peds.2009-2306">https://doi.org/10.1542/peds.2009-2306</a></li>
                                        <li>Lutz, L., Stewart, M., Hertz, D., &#38; Lyman, L. (2015). The Crossover Youth Practice Model (CYPM). Center for Juvenile Justice Reform, McCourt School of Public Policy, Georgetown University. <a href="https://static1.squarespace.com/static/5eb2bae2bb8af12ca7ab9f12/t/5f66baedae8624210b2ea987/1600568047996/CYPM-Abbreviated-Guide+%282%29.pdf">https://static1.squarespace.com/static/5eb2bae2bb8af12ca7ab9f12/t/5f66baedae8624210b2ea987/1600568047996/CYPM-Abbreviated-Guide+%282%29.pdf</a></li>
                                        <li>Rosenthal, L. (n.d.). The Initiative to End Girls’ Incarceration. Vera Institute of Justice. Retrieved May 11, 2021, from <a href="https://www.vera.org/projects/the-initiative-to-end-girls-incarceration">https://www.vera.org/projects/the-initiative-to-end-girls-incarceration</a></li>
                                        <li>Office of Juvenile Justice and Delinquency Prevention (2015). Girls and the Juvenile Justice System Policy Guidance. OJJDP Girls Study Group Series. <a href="https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf">https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UncontrolledTooltip placement="top" autohide={false} target="r20">
                    Monk, E. P. (2018). The color of punishment: African Americans, skin tone, and the criminal justice system. Ethnic and Racial Studies, 42(10), 1593–1612. <a href="https://doi.org/10.1080/01419870.2018.1508736">https://doi.org/10.1080/01419870.2018.1508736</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r21">
                    Kirk, D. S. (2008). The neighborhood context of racial and ethnic disparities in arrest. Demography, 45(1), 55–77. <a href="https://doi.org/10.1353/dem.2008.0011">https://doi.org/10.1353/dem.2008.0011</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r22">
                    Rovner, J. (2021). Racial Disparities in Youth Incarceration Persists. The Sentencing Project. <a href="https://www.sentencingproject.org/publications/racial-disparities-in-youth-incarceration-persist/">https://www.sentencingproject.org/publications/racial-disparities-in-youth-incarceration-persist/</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r23">
                    The Vera Institute of Justice. (n.d.). Vera’s 10-Year Strategy to End Girls’ Incarceration. Vera Institute of Justice. Retrieved May 11, 2021, from <a href="https://www.vera.org/publications/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration">https://www.vera.org/publications/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration/veras-10-year-strategy-to-end-girls-incarceration</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r24">
                    Ehrmann, S., Hyland, N., &#38; Puzzanchera, C. (2019). Girls in the Juvenile Justice System. National Report Series Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r25">
                    Ehrmann, S., Hyland, N., &#38; Puzzanchera, C. (2019). Girls in the Juvenile Justice System. National Report Series Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r26">
                    Ehrmann, S., Hyland, N., &#38; Puzzanchera, C. (2019). Girls in the Juvenile Justice System. National Report Series Bulletin. Office of Juvenile Justice and Delinquency Prevention. <a href="https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf">https://ojjdp.ojp.gov/sites/g/files/xyckuh176/files/pubs/251486.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r27">
                    Office of Juvenile Justice and Delinquency Prevention (2015). Girls and the Juvenile Justice System Policy Guidance. OJJDP Girls Study Group Series. <a href="https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf">https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r28">
                    Himmelstein, K. E. W., &#38; Brückner, H. (2011). Criminal-justice and school sanctions against nonheterosexual youth: A national longitudinal study. Pediatrics, 127(1), 49-57. <a href="https://doi.org/10.1542/peds.2009-2306">https://doi.org/10.1542/peds.2009-2306</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r29">
                    Lutz, L., Stewart, M., Hertz, D., &#38; Lyman, L. (2015). The Crossover Youth Practice Model (CYPM). Center for Juvenile Justice Reform, McCourt School of Public Policy, Georgetown University. <a href="https://static1.squarespace.com/static/5eb2bae2bb8af12ca7ab9f12/t/5f66baedae8624210b2ea987/1600568047996/CYPM-Abbreviated-Guide+%282%29.pdf">https://static1.squarespace.com/static/5eb2bae2bb8af12ca7ab9f12/t/5f66baedae8624210b2ea987/1600568047996/CYPM-Abbreviated-Guide+%282%29.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r30">
                    Rosenthal, L. (n.d.). The Initiative to End Girls’ Incarceration. Vera Institute of Justice. Retrieved May 11, 2021, from <a href="https://www.vera.org/projects/the-initiative-to-end-girls-incarceration">https://www.vera.org/projects/the-initiative-to-end-girls-incarceration</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r31">
                    Office of Juvenile Justice and Delinquency Prevention (2015). Girls and the Juvenile Justice System Policy Guidance. OJJDP Girls Study Group Series. <a href="https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf">https://rights4girls.org/wp-content/uploads/r4g/2016/08/OJJDP-Policy-Guidance-on-Girls.pdf</a>
                </UncontrolledTooltip>
            </div>

        );
    }
}

export default Disparities;