import React, { Component } from 'react';
import $ from 'jquery';
import Header from './HeaderComponent';

class Home extends Component {

    componentDidMount() {
        $(".arrow-btn").on("click", function(event) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(event.target).closest('.section').next().offset().top
            }, 100);
        });
    }

    render() {
        return(
            <div>
                <Header location={this.props.location} />
                <div className="section black-theme">
                    <div className="container">
                        <div className="row justify-content-center section-container align-items-center">
                            <div className="col">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 col-md-8 col-sm-9">
                                        <img src="/assets/images/Desktop.png" className="img-home img-fluid" alt="This is a web project exploring youth incarceration in America."></img>
                                    </div>
                                </div> 
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 col-sm-2 col-3">
                                        <img src="/assets/images/black-arrow.png" className="arrow-btn img-fluid" alt="Down"></img>
                                    </div>
                                </div>                          
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="section blue-theme">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="order-md-1 order-2 col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <img src="/assets/images/process-1.png" className="progress-dots img-fluid" alt="1 of 4"></img>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h1 className="section-title">How it Works</h1>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-xl-8">
                                        <p>Most people would agree that adults and children have very different needs and abilities. Many research studies have come to the same conclusion.</p>
                                        <p>Many features distinguish the juvenile justice system from its adult counterpart, but one troubling similarity stands out: an overreliance on confinement.</p>
                                        <a className="blue-theme learn-more" href="/how-it-works">Learn More</a>
                                    </div>     
                                </div>
                            </div>
                            <div className="order-md-2 order-1 col-4">
                                <img src="/assets/images/eyes.png" className="section-img img-fluid" alt="Eyes"></img>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-1 col-sm-2 col-3">
                                <img src="/assets/images/blue-arrow.png" className="arrow-btn img-fluid" alt="Down"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section dark-blue-theme">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="order-md-1 order-2 col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <img src="/assets/images/process-2.png" className="progress-dots img-fluid" alt="2 of 4"></img>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="special-title">
                                            <h1 className="section-title">The DANGERS</h1>
                                            <p>of DETENTION</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-xl-8">
                                        <p>Youths in the juvenile justice system have high rates of mental health disorder, trauma, and suicidality rates compared to their peers.</p>
                                        <p>Additionally, incarcerated students often lag behind in pass rates and have access to fewer higher-level courses.</p>
                                        <a className="dark-blue-theme learn-more" href="/dangers-of-detention">Learn More</a>
                                    </div>     
                                </div>
                            </div>
                            <div className="order-md-2 order-1 col-sm-4 col-3">
                                <img src="/assets/images/danger.png" className="section-img img-fluid" alt="Danger"></img>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-1 col-sm-2 col-3">
                                <img src="/assets/images/white-arrow.png" className="arrow-btn img-fluid" alt="Down"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section red-theme">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="order-md-1 order-2 col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <img src="/assets/images/process-3.png" className="progress-dots img-fluid" alt="3 of 4"></img>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="special-title">
                                            <h1 className="section-title">The Disparities</h1>
                                            <p>in DETENTION</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-xl-8">
                                        <p>Even after accounting for family and other predictors, there are still residual arrest differences between youths of different racial and ethnic groups.</p>
                                        <a className="red-theme learn-more" href="/disparities-in-detention">Learn More</a>
                                    </div>     
                                </div>
                            </div>
                            <div className="order-md-2 order-1 col-4">
                                <img src="/assets/images/disp.png" className="section-img img-fluid" alt="Disparities"></img>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-1 col-sm-2 col-3">
                                <img src="/assets/images/red-arrow.png" className="arrow-btn img-fluid" alt="Down"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section purple-theme">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="order-md-1 order-2 col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <img src="/assets/images/process-4.png" className="progress-dots img-fluid" alt="4 of 4"></img>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h1 className="section-title">Solutions + Steps</h1>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-xl-8">
                                        <p>Research studies across multiple jurisdictions have revealed a disturbing pattern: youth who are incarcerated are more likely to recidivate than youth who are supervised in a community-based setting, or not detained at all.</p>
                                        <p>Community-based interventions are a humane and effective alternative to the traumatic and disruptive process of confining children in juvenile facilities.</p>
                                        <a className="purple-theme learn-more" href="/solutions-and-steps">Learn More</a>
                                    </div>     
                                </div>
                            </div>
                            <div className="order-md-2 order-1 col-sm-4 col-3">
                                <img src="/assets/images/hands.png" className="section-img img-fluid" alt="Solutions"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Home;