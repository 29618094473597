import React, { Component } from 'react';
import $ from 'jquery';

class Footer extends Component {

    getTheme(path){
        let brandColor;
        switch(path){
            default:
                brandColor = "black";
                break;
            case '/get-involved':
                brandColor = "black";
                break;
            case '/about':
                brandColor = "black";
                break;
            case '/blog':
                brandColor = "black";
                break;
            case '/glossary':
                brandColor = "black";
                break;
            case '/how-it-works':
                brandColor = "dark-blue";
                break;
            case '/disparities-in-detention':
                brandColor = "red";
                break;
            case '/dangers-of-detention':
                brandColor = "white";
                break;
            case '/solutions-and-steps':
                brandColor = "purple";
                break;
        }
        return brandColor;
    }

    getNext(path) {
        let next;
        switch(path){
            default:
                next = "/home";
                break;
            case '/how-it-works':
                next = "/dangers-of-detention";
                break;
            case '/disparities-in-detention':
                next = "/solutions-and-steps";
                break;
            case '/dangers-of-detention':
                next = "/disparities-in-detention";
                break;
        }
        return next;
    }

    componentDidMount() {
        switch(this.props.location) {
            default:
                $('.extended-footer').remove();
                break;
            case '/how-it-works':
                break;
            case '/disparities-in-detention':
                break;
            case '/dangers-of-detention':
                break;
            case '/solutions-and-steps':
                break;     
        }
    }

    render() {
        return(
            <div className="article-footer container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-2 col-3">
                        <a href="/get-involved"><img src={`/assets/images/get-involved-${this.getTheme(this.props.location)}.png`} className="img-fluid" alt="Get Involved"></img></a>
                    </div>
                    <div className="col-md-2 col-3 extended-footer">
                        <a href={`#${this.props.location.slice(1)}-references`}><img src={`/assets/images/references-${this.getTheme(this.props.location)}.png`} className="img-fluid" alt="Explore References"></img></a>
                    </div>
                    <div className="col-md-2 col-3">
                        <a href="https://wustl.us6.list-manage.com/subscribe?u=5d594aed9c623b7cdaa3f913c&id=b290607fd7" rel="noreferrer" target="_blank"><img src={`/assets/images/newsletter-${this.getTheme(this.props.location)}.png`} className="img-fluid" alt="Newsletter"></img></a>
                    </div>
                    <div className="col-md-2 col-3 extended-footer">
                        <a href={this.getNext(this.props.location)}><img src={`/assets/images/keep-reading-${this.getTheme(this.props.location)}.png`} className="img-fluid" alt="Keep Reading"></img></a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;