export const GLOSSARY = 
[
    {
        id: 0,
        term: "adjudicated deliquent",
        definition: "A person is “adjudicated delinquent” when a juvenile court finds that they committed an unlawful act. In the adult criminal legal system, this is known as a “conviction.”",
        outline_icon: "/assets/images/glossary/outline-0.png",
        colored_icon: "/assets/images/glossary/colored-0.png"
    },
    {
        id: 1,
        term: "delinquency",
        definition: "The act of participating in unlawful behavior while you are too young to be held legally responsible for criminal acts (typically below 18 years old). Delinquent offenses are classified as crimes when committed by adults.",
        outline_icon: "/assets/images/glossary/outline-1.png",
        colored_icon: "/assets/images/glossary/colored-1.png"
    },
    {
        id: 2,
        term: "detention",
        definition: 'When a youth is confined to their home (“Home detention”) or held in a private or state-run residential facility while awaiting their juvenile court hearing. <em>Legal Lark’s page titles of “Dangers of Detention” and “Disparities in Detention” also incorporate discussions of incarceration, which occurs after a youth has been adjudicated delinquent.</em>',
        outline_icon: "/assets/images/glossary/outline-2.png",
        colored_icon: "/assets/images/glossary/colored-2.png"
    },
    {
        id: 3,
        term: "diversion programs",
        definition: "As an alternative to formal charge procedures, diversion programs enable youths to avoid entering the juvenile court system by participating in counseling, community service, education, and/or other social services. These rehabilitative programs may be run by a court, police department, or external agency.",
        outline_icon: "/assets/images/glossary/outline-3.png",
        colored_icon: "/assets/images/glossary/colored-3.png"
    },
    {
        id: 4,
        term: "disparities",
        definition: "The word “disparity” means “a large difference.” Disparities in arrests and incarceration rates mean that youths that belong to marginalized race, sexuality, and/or gender identity groups are more likely to be involved in the juvenile justice system.",
        outline_icon: "/assets/images/glossary/outline-4.png",
        colored_icon: "/assets/images/glossary/colored-4.png"
    },
    {
        id: 5,
        term: "e-carceration",
        definition: "E-carceration is short for “electronic incarceration.” From GPS ankle monitors to license plate readers, e-carceration refers to the state’s use of technology to monitor, track, and/or constrain people. The constant surveillance traditionally associated with prisons is able to be replicated in everyday communities, causing concerning implications for privacy and liberty.",
        outline_icon: "/assets/images/glossary/outline-5.png",
        colored_icon: "/assets/images/glossary/colored-5.png"
    },
    {
        id: 6,
        term: "gender-expansive",
        definition: "Gender-expansive is an umbrella term that describes people who do not meet and/or identify with society’s “traditional,” narrow understanding of gender expression and identity.",
        outline_icon: "/assets/images/glossary/outline-6.png",
        colored_icon: "/assets/images/glossary/colored-6.png"
    },
    {
        id: 7,
        term: "gender-responsive",
        definition: "Gender-responsive approaches involve adjusting practices and policies to respond to the unique characteristics, needs, and life experiences of the different gender identities involved with the juvenile justice system.",
        outline_icon: "/assets/images/glossary/outline-7.png",
        colored_icon: "/assets/images/glossary/colored-7.png"
    },
    {
        id: 8,
        term: "incarceration",
        definition: "If a youth has been “adjudicated delinquent” or has had their probation revoked, they may be placed in a private or state-run residential facility for a length of time determined at their disposition hearing or until the responsible state agency determines they have been rehabilitated. Youth incarceration is also known as commitment or placement.",
        outline_icon: "/assets/images/glossary/outline-8.png",
        colored_icon: "/assets/images/glossary/colored-8.png"
    },
    {
        id: 9,
        term: "juvenile justice system",
        definition: "Generally, youth under the age of 18 have not reached the “age of culpability.” This means that they are too young to be held legally responsible for criminal acts. In the juvenile justice system, academia, law enforcement, courts, and corrections are charged with working together to rehabilitate justice-involved youth so that they don’t engage in criminal behavior as adults. However, states may set different age thresholds for the transferring of cases to the adult system, including for certain offenses.",
        outline_icon: "/assets/images/glossary/outline-9.png",
        colored_icon: "/assets/images/glossary/colored-9.png"
    },
    {
        id: 10,
        term: "punitive",
        definition: "Punitive juvenile justice policies are those that focus on inflicting punishment. Fear and stereotypes have encouraged “tough on delinquency” legislative efforts that make it easier to transfer juveniles to the adult criminal legal system and promote harsher sentencing. Evidence suggests that these measures disproportionately impact minority youth and do not reduce reoffending.",
        outline_icon: "/assets/images/glossary/outline-10.png",
        colored_icon: "/assets/images/glossary/colored-10.png"
    },
    {
        id: 11,
        term: "recidivism",
        definition: "While its exact definition varies by <a href=\"https://www.doc.ks.gov/juvenile-services/committee/documentation/6-2017/Recidivism/view\" rel=\"noreferrer\" target=\"_blank\">states</a>, the reduction of recidivism is a key goal across all juvenile justice systems. The word “recidivism” is generally used to describe when a youth reoffends after being released from court custody for previous delinquent behavior.",
        outline_icon: "/assets/images/glossary/outline-11.png",
        colored_icon: "/assets/images/glossary/colored-11.png"
    },
    {
        id: 12,
        term: "rehabilitation",
        definition: "Rather than focus on punishment, rehabilitation programs for justice-involved kids aim to nurture positive behaviors and ensure the participants have the resources they need to successfully reenter society. These programs may include skills training, therapy, and substance abuse treatment.",
        outline_icon: "/assets/images/glossary/outline-12.png",
        colored_icon: "/assets/images/glossary/colored-12.png"
    },
    {
        id: 13,
        term: "restrictive housing",
        definition: "Restrictive housing, also known as “administrative segregation,” is a broad term that describes how certain youth may be physically isolated from their detention facility’s general population. There are many types of restrictive housing, including solitary confinement and medical isolation.",
        outline_icon: "/assets/images/glossary/outline-13.png",
        colored_icon: "/assets/images/glossary/colored-13.png"
    },
    {
        id: 14,
        term: "retribution",
        definition: "The word “retribution” describes punishment that is inflicted on someone in response to them committing a wrong or criminal act. Rather than focus on punishment, the juvenile justice system is supposed to champion rehabilitation and the prevention of reoffending into adulthood.",
        outline_icon: "/assets/images/glossary/outline-14.png",
        colored_icon: "/assets/images/glossary/colored-14.png"
    },
    {
        id: 15,
        term: "status offense",
        definition: "Status offenses are acts that would not be a crime if they were committed by an adult. Because of their status as a minor, a youth can be found to have violated the law for skipping school and running away from home.",
        outline_icon: "/assets/images/glossary/outline-15.png",
        colored_icon: "/assets/images/glossary/colored-15.png"
    },
    {
        id: 16,
        term: "technical violation",
        definition: "A technical violation occurs when a youth who is under court supervision breaks a rule imposed on them as part of their probation conditions or court order. These offenses are typically non-criminal, meaning they would not result in arrest if done by an adult. For example, if youth was given probation under the condition of attending school, skipping could result in their confinement in a correctional facility.",
        outline_icon: "/assets/images/glossary/outline-16.png",
        colored_icon: "/assets/images/glossary/colored-16.png"
    },
    {
        id: 17,
        term: "wraparound care",
        definition: "Rather than provide fragmented services, wraparound care centralizes community resources to support youth across various aspects of their life. By providing culturally competent, individualized service plans, wraparound care seeks to empower youth to safely thrive in their homes, schools, communities.",
        outline_icon: "/assets/images/glossary/outline-17.png",
        colored_icon: "/assets/images/glossary/colored-17.png"
    }
]