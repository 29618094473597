import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import { GLOSSARY } from '../data/glossary';

function RenderTerms({terms}) {
    const glossary = terms.map((term) => {
        return (
            <div key={term.id} className="col-lg-2 col-md-3 col-6">
                <div className="row justify-content-center">
                    <div className="col-9">
                        <img id={`term-${term.id}`} src={term.outline_icon} alt={term.term} className="term img-fluid"></img>
                    </div>
                </div>
            </div>
        );
    });
    return glossary;
}

class Glossary extends Component {

    constructor(props) {
        super(props);
 
        this.toggleModal = this.toggleModal.bind(this);
        this.updateModal = this.updateModal.bind(this);
        this.click = this.click.bind(this);
        this.mouseover = this.mouseover.bind(this);
        this.mouseout = this.mouseout.bind(this);
        this.state = {
            isModalOpen: false,
            activeTerm: '',
            activeDefinition: '',
            activeTermId: '',
            activeTermIdNum: ''
        };
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
        this.click(this.state.activeTermId, this.state.activeTermIdNum);
    }

    updateModal(term, definition, termId, termNum) {
        if(this.state.activeTermIdNum!=='') {
            this.mouseout(this.state.activeTermId, this.state.activeTermIdNum);
        }
        this.setState({
            activeTerm: term,
            activeDefinition: definition,
            activeTermId: termId,
            activeTermIdNum: termNum
        });
    }

    click(termId, termNum) {
        $(`#${termId}`).attr("src", GLOSSARY[termNum].colored_icon);
    }

    mouseover(termId, termNum) {
        $(`#${termId}`).attr("src", GLOSSARY[termNum].colored_icon);
    }

    mouseout(termId, termNum) {
        $(`#${termId}`).attr("src", GLOSSARY[termNum].outline_icon);
    }

    render() {
        return(
            <div>
                <Header location={this.props.location} />
                <div className="container">
                    <div className="row">
                        <RenderTerms terms={GLOSSARY} />
                    </div>
                </div>
                <Footer location={this.props.location} />
                <Modal size="lg" id="glossary-modal" isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>{this.state.activeTerm}</ModalHeader>
                    <ModalBody>
                        <p dangerouslySetInnerHTML={{__html: this.state.activeDefinition}}></p>
                        <Button onClick={this.toggleModal}>GOT IT</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    componentDidMount() {
        $('.term').on("mouseover", (event) => {
            let termId = event.target.id;
            let idNum = termId.split('-')[1];
            this.mouseover(termId, idNum);
        });
        $('.term').on("mouseout", (event) => {
            let termId = event.target.id;
            let idNum = termId.split('-')[1];
            this.mouseout(termId, idNum);
        });
        $('.term').on("click", (event) => {
            let termId = event.target.id;
            let idNum = termId.split('-')[1];
            this.updateModal(GLOSSARY[idNum].term, GLOSSARY[idNum].definition, termId, idNum);
            this.toggleModal();
            this.click(termId, idNum);
        });
    }
}

export default Glossary;