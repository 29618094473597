import React, { Component } from 'react';
import { Card, CardBody, CardText, CardTitle, CardSubtitle } from 'reactstrap';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import { RESOURCES } from '../data/resources';
import Support from './SupportComponent';

function RenderResources({resourceList, filter}) {
    if (filter!=="everything") {
        resourceList = resourceList.filter(r => r.type === filter);
    }
    const resources = resourceList.map((resource) => {
        return (
            <div key={resource.id} className="col-xl-3 col-lg-4 col-md-6 col-12">
                <a className="resource-link" href={resource.link} rel="noreferrer" target="_blank">
                    <Card className={`resource-card ${resource.type}`}>
                        <CardBody>
                            <CardTitle>{resource.type}</CardTitle>
                            <CardSubtitle>{resource.title}</CardSubtitle>
                            <CardText>{resource.description}</CardText>
                        </CardBody>
                    </Card>                    
                </a>
            </div>
        );
    });
    return resources;
}

class GetInvolved extends Component {

    constructor(props) {
        super(props);
 
        this.filterMain = this.filterMain.bind(this);
        this.filterResources = this.filterResources.bind(this);
        this.state = {
            resourcesActive: "filterActive",
            spotlightsActive: "",
            supportActive: "",
            resourceFilter: "everything",
            everythingActive: "filterActive",
            organizationsActive: "",
            campaignsActive: "",
            videosActive: "",
            booksActive: "",
            articlesActive: "",
            display: {
                resources: "",
                spotlights: "d-none",
                support: "d-none"
            }
        };
    }

    filterMain(selection) {
        this.setState({
            resourcesActive: "",
            spotlightsActive: "",
            supportActive: "",
            display: {
                resources: "d-none",
                spotlights: "d-none",
                support: "d-none"
            }
        });
        let filterSelection = selection+"Active";
        this.setState(prevState => ({
            [filterSelection]: "filterActive",
            display: {
                ...prevState.display,
                [selection]: ""
            }
        }));
    }

    filterResources(selection) {
        let filterSelection = selection+"Active";
        this.setState({
            everythingActive: "",
            organizationsActive: "",
            campaignsActive: "",
            videosActive: "",
            booksActive: "",
            articlesActive: "",
            [filterSelection]: "filterActive"
        });
        if(selection==="everything") {
            this.setState({
                resourceFilter: selection
            });
        }
        else {
            this.setState({
                resourceFilter: selection.slice(0, -1)
            });
        }
    }

    render() {
        return(
            <div>
                <Header location={this.props.location} />
                <div className="dark-blue-theme">
                    <div className="container center">
                        <div className="row section align-items-center">
                            <div className="col-12">
                                <img src="/assets/images/reading-and-resources.png" alt="Reading and Resources" className="fit-height img-fluid"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <button className={`filter-btn main-filter-btn ${this.state.resourcesActive}`} onClick={() => this.filterMain("resources")}>RESOURCES</button>
                            {/* <button className={`filter-btn main-filter-btn ${this.state.spotlightsActive}`} onClick={() => this.filterMain("spotlights")}>COMMUNITY SPOTLIGHTS</button> */}
                            <button className={`filter-btn main-filter-btn ${this.state.supportActive}`} onClick={() => this.filterMain("support")}>GET SUPPORT</button>
                        </div>
                    </div>
                    <div className={`row ${this.state.display.resources}`}>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <button className={`filter-btn secondary-filter-btn ${this.state.everythingActive}`} onClick={() => this.filterResources("everything")}>EVERYTHING</button>
                                    <button className={`filter-btn secondary-filter-btn ${this.state.organizationsActive}`} onClick={() => this.filterResources("organizations")}>ORGANIZATIONS</button>
                                    <button className={`filter-btn secondary-filter-btn ${this.state.campaignsActive}`} onClick={() => this.filterResources("campaigns")}>CAMPAIGNS</button>
                                    <button className={`filter-btn secondary-filter-btn ${this.state.videosActive}`} onClick={() => this.filterResources("videos")}>TO WATCH</button>
                                    <button className={`filter-btn secondary-filter-btn ${this.state.booksActive}`} onClick={() => this.filterResources("books")}>BOOKS</button>
                                    <button className={`filter-btn secondary-filter-btn ${this.state.articlesActive}`} onClick={() => this.filterResources("articles")}>ARTICLES</button>
                                </div>
                            </div>
                            <div className="row align-items-stretch" id="resource-list">
                                <RenderResources resourceList={RESOURCES} filter={this.state.resourceFilter} />
                            </div>
                        </div>
                    </div>
                    <div className={`row ${this.state.display.spotlights}`}>
                        <div className="col">
                            <br></br><br></br>
                            <h2>Coming Soon!</h2>
                        </div>
                    </div>
                    <div className={`row ${this.state.display.support}`}>
                        <div className="col">
                            <Support></Support>
                        </div>
                    </div>
                </div>
                <Footer location={this.props.location} />
            </div>

        );
    }
}

export default GetInvolved;