import React, { Component } from 'react';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import { ARTICLES } from '../data/articles';

function getTheme(section) {
    let theme = "";
    switch(section.toLowerCase()) {
        default:
            theme = "blue";
            break;
        case "how it works":
            theme = "blue";
            break;
        case "the dangers":
            theme = "dark-blue";
            break;
        case "the disparities":
            theme = "red";
            break;
        case "solutions":
            theme = "purple";
            break;        
    }
    return theme;
}

function RenderPosts({posts}) {
    const blog = posts.map((post) => {
        return (
            <div key={post.id} className="row blog-post">
                <div className="col-12 col-md-3">
                    <p className="article-section">{post.section}</p>
                    <p className="article-post-date">{post.date}<br></br>by {post.author}</p>
                    <div className="row">
                        <div className="col-7 d-none d-md-block">
                            <img src={`/assets/images/article-flag-${getTheme(post.section)}.png`} alt="" className="img-fluid"></img>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-9">
                    <h5 className="article-title"><a href={`/blog/:${post.link}`}>{post.title}</a></h5>
                    <p className="article-description">{post.description}</p>
                </div>                
            </div>
        );
    });
    return blog;
}

class Blog extends Component {

    render() {
        return(
            <div>
                <Header location={this.props.location} />
                <div className="container">
                    <div className="row justify-content-between" id="blog-feed">
                        <div className="col-3 d-none d-md-block">
                            <img src="/assets/images/BLOG.png" alt="Blog" className="img-fluid"></img>
                        </div>
                        <div className="col-12 col-md-8">
                            <RenderPosts posts={ARTICLES} />
                        </div>
                    </div>
                </div>
                <Footer location={this.props.location} />
            </div>

        );
    }
}

export default Blog;