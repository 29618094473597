import React, { Component } from 'react';
import $ from 'jquery';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import { ARTICLES } from '../data/articles';

class Article extends Component {

    constructor(props) {
        super(props);
 
        this.getTheme= this.getTheme.bind(this);
    }

    getTheme(section) {
        let theme = "";
        switch(section.toLowerCase()) {
            default:
                theme = "blue";
                break;
            case "how it works":
                theme = "blue";
                break;
            case "the dangers":
                theme = "dark-blue";
                break;
            case "the disparities":
                theme = "red";
                break;
            case "solutions":
                theme = "purple";
                break;        
        }
        return theme;
    }

    render() {
        const articles = ARTICLES.filter((a) => a.link===this.props.link.slice(1));
        const article = articles[0];
        if (articles.length < 1 ) {
            return(
                <div>
                    <Header location="/home" />
                    <div className="container not-found">
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <p>Page Not Found</p>
                            </div>
                        </div>
                    </div>                    
                </div>
            )
        }
        return(
            <div>
                <Header location={this.props.location} />
                <div className="container">
                    <div className="row article-head">
                        <div className="offset-lg-1 col-lg-2 col-md-3 col-5">
                            <div className="row">
                                <div className="col-8">
                                    <a href="/blog"><img src="/assets/images/more-posts.png" alt="More Posts" className="img-fluid"></img></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-1 col-1 d-none d-md-block">
                            <img src={`/assets/images/article-decor-${this.getTheme(article.section)}.png`} alt="" className="img-fluid"></img>
                        </div>
                        <div className="offset-md-1 col-lg-8 col-md">
                            <p className="article-title">{article.title}</p>
                            <div className="article-subheader">
                                <p className="article-section">{article.section}</p>
                                <p className="article-post-date">{article.date} – by {article.author}</p>
                            </div>
                            <div className="row">
                                <div className="article-content col" dangerouslySetInnerHTML={{__html: article.content}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer location={this.props.location} />
            </div>
        );
    }

    componentDidMount() {
        $('.article-content img').attr("class", "img-fluid");
    }
}

export default Article;