export const RESOURCES = 
[
  {
    "id": 0,
    "title": "From Prison Cells to PhD (P2P)",
    "link": "https://www.fromprisoncellstophd.org/meet-the-team.html",
    "type": "organization",
    "description": "Active in 21 states, P2P helps people with criminal convictions obtain higher education. Supported through various reentry challenges, program participants are empowered to reach their full academic, personal, and professional potential."
  },
  {
    "id": 1,
    "title": "The Annie E. Casey Foundation (AECF)",
    "link": "https://www.aecf.org/",
    "type": "organization",
    "description": "Based in Baltimore, AECF make grants to organizations across the US to invest in struggling families and communities. AECF aims to create a brighter future for children at risk of poor educational, economic, social and health outcomes."
  },
  {
    "id": 2,
    "title": "W. Haywood Burns Institute (BI)",
    "link": "https://burnsinstitute.org/",
    "type": "organization",
    "description": "A Black-led national, non-profit, BI works to establish a community centered approach of justice administration that is anchored in structural well-being, fairness, and equity."
  },
  {
    "id": 3,
    "title": "Center for Children’s Law and Policy (CCLP)",
    "link": "https://www.cclp.org/donate/",
    "type": "organization",
    "description": "CCLP is a public interest law and policy organization focused on reforming juvenile justice and other systems that affect troubled and at-risk children."
  },
  {
    "id": 4,
    "title": "Reclaiming Futures",
    "link": "https://www.reclaimingfutures.org/donate",
    "type": "organization",
    "description": "Reclaiming Futures is a national public health-oriented juvenile justice reform organization dedicated to improving behavioral health outcomes for youth and families."
  },
  {
    "id": 5,
    "title": "National Juvenile Defender Center (NJDC)",
    "link": "https://njdc.info/about-njdc/donate/",
    "type": "organization",
    "description": "NJDC is a non-profit, non-partisan organization dedicated to promoting justice for all children by improving access to counsel and quality of representation for children in the justice system."
  },
  {
    "id": 6,
    "title": "BreakFree Education",
    "link": "https://www.breakfree-ed.org/support",
    "type": "organization",
    "description": "BreakFree Education aims to radically improve education in the juvenile justice system by developing quality educational programs, working with policymakers, and partnering with juvenile justice agenices."
  },
  {
    "id": 7,
    "title": "Center for Community Alternatives (CCA)",
    "link": "https://www.communityalternatives.org/programs/",
    "type": "organization",
    "description": "Based in New York, CCA provides direct services to justice-involved adults and juveniles, diverting hundreds from incarceration."
  },
  {
    "id": 8,
    "title": "Community Works",
    "link": "https://donatenow.networkforgood.org/communityworks",
    "type": "organization",
    "description": "Community Works seeks to disrupt the widespread impact of incarceration by empowering individuals, families and communities. It provides a combination of direct services, restorative practice, and client-centered advocacy."
  },
  {
    "id": 9,
    "title": "Dream Builders 4 Equity (DB4E)",
    "link": "https://www.dreambuilders4equity.org/mission-vision",
    "type": "organization",
    "description": "DB4E provides at-risk, underserved youth with mentorship, entrepreneurship, scholarship, and leadership training. Its innovative youth-centered model provides its participants with the agency to transform themselves and their communities."
  },
  {
    "id": 10,
    "title": "ScholarCHIPS",
    "link": "https://www.scholarchipsfund.org/",
    "type": "organization",
    "description": "ScholarCHIPS provides college scholarships, mentoring and a peer support network to children of incarcerated parents, inspiring them to complete their college education."
  },
  {
    "id": 11,
    "title": "Youth First",
    "link": "http://www.youthfirstinitiative.org/",
    "type": "organization",
    "description": "Youth First is a national advocacy campaign to end the incarceration of youth. Their work focuses on closing youth prisons and investing in youth programs and community-based alternatives to incarceration."
  },
  {
    "id": 12,
    "title": "Youth Sentencing and Reentry Project (YSRP)",
    "link": "https://ysrp.org/support-us/",
    "type": "organization",
    "description": "YSRP provides direct service and policy advocacy to keep children out of adult jails and prisons and to enhance the quality of representation Juvenile Lifers receive at resentencing."
  },
  {
    "id": 13,
    "title": "Youth Art & Self-Empowerment Project (YASP)",
    "link": "http://www.yasproject.com/",
    "type": "organization",
    "description": "Based in Philadelphia, YASP is a youth-led movement to end youth incarceration. YASP provides space for incarcerated young people to express themselves creatively and to develop as leaders."
  },
  {
    "id": 14,
    "title": "Appleseed Network",
    "link": "https://www.appleseednetwork.org/meet-the-network.html",
    "type": "organization",
    "description": "With centers in 14 states, DC, and Mexico, Appleseed is a network of non-profits dedicated to advancing justice and opportunities that help low-income people and working families build better lives."
  },
  {
    "id": 15,
    "title": "Justice for Families (J4F)",
    "link": "https://www.justice4families.org/home/donate-2/",
    "type": "organization",
    "description": "J4F is a national alliance of local organizations committed to ending youth incarceration. Founded and run by families who have been directly impacted by the juvenile justice system, J4F focuses on leadership development, strategic partnerships, and policy advoacy."
  },
  {
    "id": 16,
    "title": "Invest in Me - Connecticut",
    "link": "https://ctja.org/invest-in-me-ct",
    "type": "campaign",
    "description": ""
  },
  {
    "id": 17,
    "title": "The Final 5 - Illinois",
    "link": "https://www.thefinal5campaign.com/about",
    "type": "campaign",
    "description": ""
  },
  {
    "id": 18,
    "title": "Invest Don’t Arrest - Kansas",
    "link": "https://www.progenyks.com/join-us",
    "type": "campaign",
    "description": ""
  },
  {
    "id": 19,
    "title": "No More Youth Prisons - Louisiana",
    "link": "https://www.nomoreyouthprisons.org/about-the-fight",
    "type": "campaign",
    "description": ""
  },
  {
    "id": 20,
    "title": "Care Not Control - Pennsylvania",
    "link": "https://campaigns.organizefor.org/petitions/care-not-control?share=ae34b1fc-a85a-4274-95dc-d031f7e2fb5d&source=email-share-button&utm_source=email",
    "type": "campaign",
    "description": ""
  },
  {
    "id": 21,
    "title": "Repeal Act 33 - Pennsylvania",
    "link": "http://www.yasproject.com/stop-act-33.html",
    "type": "campaign",
    "description": ""
  },
  {
    "id": 22,
    "title": "Netflix: Coded Bias",
    "link": "https://www.codedbias.com/",
    "type": "video",
    "description": ""
  },
  {
    "id": 23,
    "title": "Netflix: Time: The Kalief Browder Story",
    "link": "https://www.netflix.com/watch/80187052?source=35",
    "type": "video",
    "description": ""
  },
  {
    "id": 24,
    "title": "Youtube: Life After Juvenile Detention",
    "link": "https://www.youtube.com/watch?v=yO9bMkCFI9s",
    "type": "video",
    "description": ""
  },
  {
    "id": 25,
    "title": "Youtube: Inside Juvenile Detention",
    "link": "https://www.youtube.com/watch?v=C-0bURq6wBo",
    "type": "video",
    "description": ""
  },
  {
    "id": 26,
    "title": "Youtube: An insider’s plan for rehabilitating the juvenile justice system",
    "link": "https://www.youtube.com/watch?v=TOxpjjzP6lM",
    "type": "video",
    "description": ""
  },
  {
    "id": 27,
    "title": "Kids Imprisoned",
    "link": "https://kidsimprisoned.news21.com/videos/",
    "type": "video",
    "description": ""
  },
  {
    "id": 28,
    "title": "Race After Technology: Abolitionist Tools for the New Jim Code - Ruha Benjamin",
    "link": "https://www.ruhabenjamin.com/race-after-technology",
    "type": "book",
    "description": ""
  },
  {
    "id": 29,
    "title": "Captivating Technology: Race, Carceral Technoscience, and Liberatory Imagination in Everyday Life - Ruha Benjamin",
    "link": "https://www.ruhabenjamin.com/captivating-technology",
    "type": "book",
    "description": ""
  },
  {
    "id": 30,
    "title": "Burning Down the House: the End of Juvenile Prison - Nell Bernstein",
    "link": "https://www.amazon.com/Burning-Down-House-Juvenile-Prison/dp/1620971313",
    "type": "book",
    "description": ""
  },
  {
    "id": 31,
    "title": "Felon: Poems - Reginald Dwayne Betts",
    "link": "https://www.amazon.com/Felon-Poems-Reginald-Dwayne-Betts/dp/0393542033",
    "type": "book",
    "description": ""
  },
  {
    "id": 32,
    "title": "The War on Kids: How American Juvenile Justice Lost Its Way - Cara H. Drinan",
    "link": "https://www.amazon.com/War-Kids-American-Juvenile-Justice/dp/0190605553",
    "type": "book",
    "description": ""
  },
  {
    "id": 33,
    "title": "The Evolution of the Juvenile Court - Barry C. Feld",
    "link": "https://www.amazon.com/Evolution-Juvenile-Court-Politics-Criminalizing/dp/1479895695",
    "type": "book",
    "description": ""
  },
  {
    "id": 34,
    "title": "Caught Up: Girls, Surveillance, and Wraparound Incarceration - Jerry Flores",
    "link": "https://www.amazon.com/Caught-Up-Surveillance-Wraparound-Incarceration-ebook/dp/B01I4VQSKW",
    "type": "book",
    "description": ""
  },
  {
    "id": 35,
    "title": "Pushout: the Criminalization of Black Girls in Schools - Monique Morris",
    "link": "https://www.amazon.com/Pushout-Criminalization-Black-Girls-Schools/dp/1620970945",
    "type": "book",
    "description": ""
  },
  {
    "id": 36,
    "title": "Learning Behind Bars - Melinda D. Anderson",
    "link": "https://www.theatlantic.com/education/archive/2016/06/learning-behind-bars/485663/",
    "type": "article",
    "description": ""
  },
  {
    "id": 37,
    "title": "Incarcerated youth deserve a quality education, and many don’t get one - Sino Esthappan & Victoria Lee",
    "link": "https://www.urban.org/urban-wire/incarcerated-youth-deserve-quality-education-and-many-dont-get-one",
    "type": "article",
    "description": ""
  },
  {
    "id": 38,
    "title": "Life Without Parole for Kids Is Cruelty With No Benefit - Brandon L. Garrett",
    "link": "https://www.theatlantic.com/ideas/archive/2020/10/life-without-parole-kids-cruelty-no-benefit/616757/",
    "type": "article",
    "description": ""
  },
  {
    "id": 39,
    "title": "AI is sending people to jail—and getting it wrong - Karen Hao",
    "link": "https://www.technologyreview.com/2019/01/21/137783/algorithms-criminal-justice-ai/",
    "type": "article",
    "description": ""
  },
  {
    "id": 40,
    "title": "Microsoft’s iron cage: Prison surveillance and e-carceration - Michael Kwet",
    "link": "https://www.aljazeera.com/features/2020/12/21/microsofts-iron-cage-prison-surveillance-and-e-carceral-state",
    "type": "article",
    "description": ""
  },
  {
    "id": 41,
    "title": "Is Juvenile Justice Beyond Repair? - Juleyka Lantigua-Williams",
    "link": "https://www.theatlantic.com/politics/archive/2016/11/is-juvenile-justice-beyond-repair/507815/",
    "type": "article",
    "description": ""
  },
  {
    "id": 42,
    "title": "What If This Were Your Kid?' - Molly McCluskey",
    "link": "https://www.theatlantic.com/politics/archive/2017/12/juvenile-solitary-confinement/548933/",
    "type": "article",
    "description": ""
  },
  {
    "id": 43,
    "title": "Why Everyone Should Care About Mass E-carceration - Patricia J. Williams",
    "link": "https://www.thenation.com/article/archive/surveillance-prison-race-technology/",
    "type": "article",
    "description": ""
  },

]