export const ARTICLES = 
[
    {
      "id": 0,
      "title": "USA Juvenile Justice System Timeline",
      "author": "Helen Webley-Brown",
      "description": "From 1800 to 2021, this timeline highlights the major milestones of the US juvenile justice system's development.",
      "section": "How it Works",
      "date": "7.06.2021",
      "content": "<img src=\"/assets/images/blog/Timeline.png\" alt=\"Timeline\"></img><img src=\"/assets/images/blog/Timeline-2.png\"  alt=\"Timeline\"></img><img src=\"/assets/images/blog/References.png\" alt=\"References\"></img><img src=\"/assets/images/blog/References-2.png\" alt=\"References\"></img>",
      "link": "juvenile-justice-timeline"
    }
]