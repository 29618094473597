import React, { Component } from 'react';
import Header from './HeaderComponent';
import Footer from './FooterComponent';

class About extends Component {

    render() {
        return(
            <div>
                <Header location={this.props.location} />
                <div className="black-theme">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <img src="/assets/images/dates.png" className="img-fluid about-padded" alt="6/2021 to 8/2021"></img>
                            </div>
                            <div className="col-md-7">
                                <a href="https://wustl.us6.list-manage.com/subscribe?u=5d594aed9c623b7cdaa3f913c&id=b290607fd7" rel="noreferrer" target="_blank"><img id="subscribe" src="/assets/images/subscribe-for-more.png" className="img-fluid about-padded" alt="Subscribe for more"></img></a>
                                <div className="row justify-content-end">
                                    <div className="col-2">
                                        <a href="https://wustl.us6.list-manage.com/subscribe?u=5d594aed9c623b7cdaa3f913c&id=b290607fd7" rel="noreferrer" target="_blank"><img id="up-arrow" src="/assets/images/black-arrow.png" className="img-fluid" alt="up"></img></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <img src="/assets/images/our-mission.png" className="img-fluid" alt="Our Mission"></img>
                            </div>
                            <div className="col-md-6">
                                <div className="d-block d-md-none">
                                    <p className="mission">Legal Lark distills evidence-based research on the causes, criticisms, and consequences of youth incarceration. We provide accessible resources to combat misinformation and challenge punitive policies.</p>
                                </div>
                                <div className="d-none d-md-block d-lg-none">
                                    <p className="mission mission-right">Legal Lark distills evidence-based research on the causes, criticisms, and consequences of youth incarceration. We provide accessible resources to combat misinformation and challenge punitive policies.</p>
                                </div>
                                <div className="d-none d-lg-block d-xl-none">
                                    <p className="mission mission-right mission-md">Legal Lark distills evidence-based research on the causes, criticisms, and consequences of youth incarceration. We provide accessible resources to combat misinformation and challenge punitive policies.</p>
                                </div>
                                <div className="d-none d-xl-block">
                                    <p className="mission mission-right mission-xl">Legal Lark distills evidence-based research on the causes, criticisms, and consequences of youth incarceration. We provide accessible resources to combat misinformation and challenge punitive policies.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="order-1 col-md-5 col-8">
                                <img src="/assets/images/graphs-text.png" className="img-fluid" alt="Graphs are used to help us understand complex issues."></img>
                            </div>
                            <div className="order-3 order-md-2 col-md-4">
                                <img src="/assets/images/graphs.png" className="img-fluid about-double-padded" alt="Graphs are used to help us understand complex issues."></img>
                            </div>
                            <div className="order-2 order-md-3 col-md-2 col-4">
                                <img src="/assets/images/graphs-text-2.png" className="img-fluid" alt="Graphs are used to help us understand complex issues."></img>
                            </div>
                        </div>
                        <br></br><br></br>
                        <div className="row">
                            <div className="order-1 col-md-6 col-8">
                                <img src="/assets/images/OUR.png" className="img-fluid" alt="Our Team"></img>
                            </div>
                            <div className="order-3 order-md-2 col-md-6 col-12">
                                <div className="row">
                                    <div className="col-8">
                                        <img src="/assets/images/BREAKDOWN.png" className="img-fluid about-double-padded" alt="Breakdown"></img>
                                    </div>
                                    <div className="col-12">
                                        <p className="staff-text">
                                            HELEN WEBLEY-BROWN: PROJECT MANAGER<br></br>
                                            CLAIRE MCSHANE: WEB DEVELOPER<br></br>
                                            JULIE CHAN: STAFF WRITER<br></br>
                                            LU GILLESPIE: UI DESIGNER
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="order-2 order-md-3 col-md-8 col-11">
                                <img src="/assets/images/TEAM.png" className="img-fluid" alt="Our Team"></img>
                            </div>
                            <div className="d-none d-md-block order-4 col-md-4 col-8">
                                <img src="/assets/images/our-team-graphic.png" className="img-fluid" alt="Our Team graphic"></img>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-7">
                                <img src="/assets/images/staff.png" className="img-fluid about-double-padded" alt="Staff members Helen Webley-Brown, Julie Chan, Claire McShane, and Lu Gillespie"></img>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-md-center">
                            <div className="col-md-10">
                                <div className="row align-items-center justify-content-md-center">
                                    <div className="col col-md-4">
                                        {/* <div className="row">
                                            <div className="col-8 col-md-12"> */}
                                                <img src="/assets/images/CONTACT.png" className="img-fluid about-double-padded" alt="Contact"></img>
                                            {/* </div>
                                        </div>   */}
                                    </div>
                                    <div className="col-2 col-md-1">
                                        <a href="https://us6.list-manage.com/contact-form?u=5d594aed9c623b7cdaa3f913c&form_id=9ebc3540e8ea3a72398f4b5bf81717b3" rel="noreferrer" target="_blank"><img src="/assets/images/envelope.png" className="img-fluid" alt="LinkedIn"></img></a>
                                    </div>
                                    <div className="col-2 col-md-1">
                                        <a href="https://www.linkedin.com/company/legal-lark/" rel="noreferrer" target="_blank"><img src="/assets/images/linked-in.png" className="img-fluid" alt="LinkedIn"></img></a>
                                    </div> 
                                    {/* <div className="col col-md-4 contact-text">

                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-md-center about-padded">
                            <div className="order-md-1 order-2 col-10 col-md-5 col-lg-4">
                                <img src="/assets/images/special-thanks.png" className="img-fluid about-double-padded" alt="Special Thanks"></img>
                            </div>
                            <div className="offset-md-1 offset-lg-2 order-md-2 order-1 col-12 col-md-6 col-lg-4">
                                <div id="funding-box" className="d-block">
                                    <p>FUNDING PROVIDED BY THE GEPHARDT INSTITUTE’S CIVIC SCHOLAR PROGRAM.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center about-padded thanked-names">
                            <div className="col-12 col-lg-10">
                                <div className="row">
                                    <div className="col-md-auto">
                                        <p>GENESIS STEELE</p>
                                    </div>
                                    <div className="col d-none d-md-block">
                                        <div className="pill-filler"></div>
                                    </div>
                                    <div className="col-md-auto">
                                        <p>DWAYNE T. JAMES</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-auto">
                                        <p>MAXINE CLARK</p>
                                    </div>
                                    <div className="col d-none d-md-block">
                                        <div className="rectangle-filler"></div>
                                    </div>
                                    <div className="col-md-auto">
                                        <p>JEFFERY MATTHEWS</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-auto">
                                        <p>THERESA TRAN KOUO</p>
                                    </div>
                                    <div className="col d-none d-md-block">
                                        <div className="pill-filler"></div>
                                    </div>
                                    <div className="col-md-auto">
                                        <p>BOB FOX</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-auto">
                                        <p>KIRSTEN SMITH</p>
                                    </div>
                                    <div className="col d-none d-md-block">
                                        <div className="rectangle-filler"></div>
                                    </div>
                                    <div className="col-md-auto">
                                        <p>SPENCER WILLIAMS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 about-padded">
                                <p id="font-attr"><em>WITH FONT ACKNOWLEDGEMENTS:</em></p>
                                <p id="tiny-attr" className="font-link"><a href="https://velvetyne.fr/fonts/tiny/" rel="noreferrer" target="_blank">TINY (160) by JACK HALTEN FAHNESTOCK</a></p>
                                <p id="inter-attr" className="font-link"><a href="https://fonts.google.com/specimen/Inter#pairings" rel="noreferrer" target="_blank">INTER (OPEN FONT LICENSE) BY GOOGLE FONTS</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer location={this.props.location} />
            </div>

        );
    }
}

export default About;