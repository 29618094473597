import React, { Component } from 'react';
import $ from 'jquery';
import { UncontrolledTooltip } from 'reactstrap';
import { Waypoint } from 'react-waypoint';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Headroom from 'react-headroom';

class Solutions extends Component {

    render() {
        return(
            <div>
                <Headroom>
                    <Header location={this.props.location} />
                </Headroom>
                <div className="purple-theme">
                    <div className="container">
                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-6 col-4">
                                                    <img src="/assets/images/process-4.png" className="progress-dots img-fluid" alt="4 of 4"></img>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="section-title">Solutions + Steps</h1>
                                                </div>
                                            </div>                                    
                                        </div>
                                        <div className="col-12">
                                            <br></br>
                                            <p className="main-text">Research studies across multiple jurisdictions have revealed a disturbing pattern: youth who are incarcerated are more likely to recidivate than youth who are supervised in a <span className="subarticle">community-based</span> setting, or not detained at all. <span id="r32" className="ref-number">[1]</span></p>
                                            <p className="main-text d-none d-sm-block">Charged with the rehabilitation and safeguarding of society’s most vulnerable, the system is failing its job and harming children.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-sm-none row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Charged with the rehabilitation and safeguarding of society’s most vulnerable, the system is failing its job and harming children.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col-md-6">
                                            <p className="supplemental-text">With juvenile delinquency being correlated with everything from increased rates of substance abuse to dropping out of school, it is crucial that we fix the juvenile justice system. <span id="r33" className="ref-number">[2]</span></p>
                                        </div>
                                        <Waypoint onEnter={() => {$('#spill').addClass('spill');}}>
                                        <div className="col-10 col-md-5 img-overlay">
                                            <img src="/assets/images/bottle.png" alt="" className="img-fluid"></img>
                                            <img id="spill" src="/assets/images/spill.png" alt="" className="img-fluid"></img>
                                        </div>
                                        </Waypoint>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">In simple terms a successful juvenile justice system would be one that:</p>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-3 col-md-2 col-lg-1">
                                                    <img src="/assets/images/success-1.png" alt="1" className="img-fluid"></img>
                                                </div>
                                                <div className="col col-lg-9">
                                                    <p className="supplemental-text">Prevented continued involvement in the criminal legal system</p>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="row align-items-center">
                                                <div className="col-3 col-md-2 col-lg-1">
                                                    <img src="/assets/images/success-2.png" alt="2" className="img-fluid"></img>
                                                </div>
                                                <div className="col col-lg-9">
                                                    <p className="supplemental-text">Helped youth transition from the system into a “crime-free and productive” adulthood <span id="r34" className="ref-number">[3]</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">But, layers of disadvantage, systemic inequities, and the unique needs and capabilities of young people, makes the work to improve the juvenile justice system anything but simple.</p>
                                            <br></br>
                                            <p className="main-text d-none d-sm-block">And, improving the juvenile justice system doesn’t just mean reducing recidivism. A successful system must address the root causes of juvenile delinquency and support the long-term success of those in its care.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-sm-none row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">And, improving the juvenile justice system doesn’t just mean reducing recidivism. A successful system must address the root causes of juvenile delinquency and support the long-term success of those in its care.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="main-text">To do so, we must invest in and champion the improvement of youth development outcomes like educational attainment, behavioral health improvements, and skills training. <span id="r35" className="ref-number">[4]</span></p>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-md-10">
                                                    <img src="/assets/images/youth-investment.png" alt="" className="img-fluid"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row scroll-section justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="sticky">
                                    <div className="row section align-items-center">
                                        <div className="col">
                                            <p className="main-text">Children don’t need cages; they need community support and wraparound care to support their development.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div className="row">
                                    <div className="col">
                                        <p className="main-text">In collaboration with several leading juvenile justice organizations, Elizabeth Seigle, Nastassia Walsh, and <a id="weber" href="https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf" rel="noreferrer" target="_blank">Josh Weber presented</a> several core principles and policy recommendations to help achieve these goals. <span id="r36" className="ref-number">[5]</span></p>
                                        <br></br>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-11">
                                        <div className="row align-items-sm-center">
                                            <div className="col-3 col-md-2 col-lg-1">
                                                <img src="/assets/images/principle-1.png" alt="1" className="img-fluid"></img>
                                            </div>
                                            <div className="col">
                                                <p className="core-principles">BASE SUPERVISION, SERVICE, AND RESOURCE-ALLOCATION DECISIONS OR THE RESULTS OF VALIDATED RISK AND NEEDS ASSESSMENTS</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row align-items-sm-center">
                                            <div className="col-3 col-md-2 col-lg-1">
                                                <img src="/assets/images/principle-2.png" alt="2" className="img-fluid"></img>
                                            </div>
                                            <div className="col">
                                                <p className="core-principles">ADOPT AND EFFECTIVELY IMPLEMENT PROGRAMS AND SERVICES DEMONSTRATED TO REDUCE RECIDIVISM AND IMPROVE OTHER YOUTH OUTCOMES, AND USE DATA TO EVALUATE SYSTEM PERFORMANCE AND DIRECT SYSTEM IMPROVEMENTS</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row align-items-sm-center">
                                            <div className="col-3 col-md-2 col-lg-1">
                                                <img src="/assets/images/principle-3.png" alt="3" className="img-fluid"></img>
                                            </div>
                                            <div className="col">
                                                <p className="core-principles">EMPLOY A COORDINATED APPROACH ACROSS SERVICE SYSTEMS TO ADDRESS YOUTH’S NEEDS</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row align-items-sm-center">
                                            <div className="col-3 col-md-2 col-lg-1">
                                                <img src="/assets/images/principle-4.png" alt="4" className="img-fluid"></img>
                                            </div>
                                            <div className="col">
                                                <p className="core-principles">TAILOR SYSTEM POLICIES, PROGRAMS, AND SUPERVISION TO REFLECT THE DISTINCT DEVELOPMENTAL NEEDS OF ADOLESCENTS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-10">
                                <div id="support-buttons" className="row">
                                    <div className="col-md-8">
                                        <img src="/assets/images/what-can-you-do.png" alt="What can you do?" className="img-fluid"></img>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <a className="btn main-article-button" href="/get-involved" role="button">Support organizations that are fighting for reform and/or working to support justice-involved youth</a>
                                    </div>
                                </div>
                                <br></br><br></br>
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <a className="btn main-article-button" href="/blog" role="button">Talk to your friends and family to help tackle misinformation</a>
                                    </div>
                                </div>
                                <br></br><br></br>
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <a className="btn main-article-button" href="https://www.commoncause.org/find-your-representative/" rel="noreferrer" target="_blank" role="button">Contact your elected officials about their plans to fix the juvenile justice system</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer location="/solutions-and-steps"/>
                    <div id="solutions-and-steps-references">
                        <div className="references purple-transition-theme">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <h1>References</h1>
                                    </div>
                                    <ol>
                                        <li>Petteruti, A., Walsh, N., &#38; Velázquez, T. (2009). The Costs of Confinement: Why Good Juvenile Justice Policies Make Good Fiscal Sense. Public Welfare Foundation. <a href="https://www.ojp.gov/ncjrs/virtual-library/abstracts/costs-confinement-why-good-juvenile-justice-policies-make-good">https://www.ojp.gov/ncjrs/virtual-library/abstracts/costs-confinement-why-good-juvenile-justice-policies-make-good</a></li>
                                        <li>Robertson, A. A., Fang, Z., Weiland, D., Joe, G., Gardner, S., Dembo, R., Mcreynolds, L., Dickson, M., Pankow, J., Dennis, M., &#38; Elkington, K. (2020). Recidivism Among Justice-Involved Youth: Findings From JJ-TRIALS. Criminal Justice and Behavior, 47(9), 1059–1078. <a href="https://doi.org/10.1177/0093854820922891">https://doi.org/10.1177/0093854820922891</a></li>
                                        <li>Walsh, N., &#38; Weber, J. (2014). Measuring and Using Juvenile Recidivism Data to Inform Policy, Practice, and Resource Allocation. Council of State Governments Justice Center. <a href="https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf">https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf</a></li>
                                        <li>Seigle, E., Walsh, N., &#38; Weber, J. (2014). Core Principles for Reducing Recidivism and Improving Other Outcomes for Youth in the Juvenile Justice System. Council of State Governments Justice Center. <a href="https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf">https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf</a></li>
                                        <li>Seigle, E., Walsh, N., &#38; Weber, J. (2014). Core Principles for Reducing Recidivism and Improving Other Outcomes for Youth in the Juvenile Justice System. Council of State Governments Justice Center. <a href="https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf">https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UncontrolledTooltip placement="top" autohide={false} target="r32">
                    Petteruti, A., Walsh, N., &#38; Velázquez, T. (2009). The Costs of Confinement: Why Good Juvenile Justice Policies Make Good Fiscal Sense. Public Welfare Foundation. <a href="https://www.ojp.gov/ncjrs/virtual-library/abstracts/costs-confinement-why-good-juvenile-justice-policies-make-good">https://www.ojp.gov/ncjrs/virtual-library/abstracts/costs-confinement-why-good-juvenile-justice-policies-make-good</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r33">
                    Robertson, A. A., Fang, Z., Weiland, D., Joe, G., Gardner, S., Dembo, R., Mcreynolds, L., Dickson, M., Pankow, J., Dennis, M., &#38; Elkington, K. (2020). Recidivism Among Justice-Involved Youth: Findings From JJ-TRIALS. Criminal Justice and Behavior, 47(9), 1059–1078. <a href="https://doi.org/10.1177/0093854820922891">https://doi.org/10.1177/0093854820922891</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r34">
                    Walsh, N., &#38; Weber, J. (2014). Measuring and Using Juvenile Recidivism Data to Inform Policy, Practice, and Resource Allocation. Council of State Governments Justice Center. <a href="https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf">https://www.scstatehouse.gov/CommitteeInfo/HouseLegislativeOversightCommittee/AgencyWebpages/DJJ/Recidivism%20Data%20-%20Measuring%20and%20Using%20Juvenile%20Data%20by%20Council%20of%20State.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r35">
                    Seigle, E., Walsh, N., &#38; Weber, J. (2014). Core Principles for Reducing Recidivism and Improving Other Outcomes for Youth in the Juvenile Justice System. Council of State Governments Justice Center. <a href="https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf">https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf</a>
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" autohide={false} target="r36">
                    Seigle, E., Walsh, N., &#38; Weber, J. (2014). Core Principles for Reducing Recidivism and Improving Other Outcomes for Youth in the Juvenile Justice System. Council of State Governments Justice Center. <a href="https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf">https://csgjusticecenter.org/wp-content/uploads/2020/01/Juvenile-Justice-White-Paper-with-Appendices-.pdf</a>
                </UncontrolledTooltip>
            </div>

        );
    }
}

export default Solutions;